:root {
  scroll-behavior: smooth;
  /* color */
  --gary:#817c8c;
  --white:#ffffff;
  --black:#000;
  --orange:#ff7b29;
  /* font-size */
  --fontSize18:18px;
  --fontSize64:64px
}

.navbarSection{
  background: var(--white);
  min-height: 90px;
  width: 100%;
  box-shadow: 0px 3px 20px rgb(0 0 0 / 8%);
  position: sticky;
  top: 0;
  z-index: 10;
}
.logo {
  width: 115px;
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-expand-lg .navbar-nav .nav-link{
  color: var(--gary);
  font-size: var(--fontSize18);
}
.navbar-dark .navbar-nav .nav-link.active{
color: var(--black);
font-size: var(--fontSize18);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("./assets/menu.webp");
}



.w-90 {
  width: 90%;
}

/* Navbar Ends */

/* Banner css starts */

.banner-left-wrapper .banner-main-text {
  color: var(--orange);
  letter-spacing: 0.01em;
  font-weight: 700;
  font-size: var(--fontSize64);
  line-height: 80px;
}
.banner-left-wrapper .black-font {
  color: var(--black);
}
.banner-small-text {
  color: var(--gary);
  letter-spacing: 0.01em;
  font-style: normal;
  font-weight: 400;
  font-size: var(--fontSize34);
  line-height: 32px;
  font-family: "Inter";
  margin-top: 34px !important;
}
.get-started-wrapper button {
  outline: none;
  border: none;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  background: #3d51fb;
  box-shadow: 0px 25px 50px rgba(61, 81, 251, 0.15);
  border-radius: 9px;
  padding: 10px 40px;
}
.margin-top-36 {
  margin-top: 36px !important;
}
.play-btn img {
  width: 56px;
  height: 56px;
  object-fit: cover;
}
.how-it-works {
  margin-left: 46px;
}
.how-it-works-text {
  color: #000000;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  margin-left: 22px;
}
.reach-wrapper {
  padding: 12px;
  max-width: 208px;
  box-shadow: 0px 24.2003px 48.4005px rgb(0 0 0 / 5%);
  border-radius: 15.4882px;
  background: #ffffff;
  border: 0.193602px solid rgba(0, 0, 0, 0.25);
  margin-left: auto;
}
.reach-wrapper img {
  width: 22px;
  height: 22px;
  object-fit: cover;
}
.reach-text {
  margin-left: 8px;
}
.reach-number {
  color: #000000;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 19.3602px;
  line-height: 25px;
  margin-top: 6px !important;
}
.last-visit span {
  color: #000;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13.5521px;
  line-height: 25px;
  margin-top: 3px !important;
}
.blue-text {
  color: #3d51fb !important;
}
.right-banner-img img {
  /* max-width: 396px;
  height: 404px;
  object-fit: cover; */
  width: 100%;
}
.message-icon img {
  filter: drop-shadow(4px 4px 0px rgba(0, 8, 50, 0.1));
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.thank-you-text .thank-you-msg {
  color: #000000;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15.4882px;
  line-height: 25px;
}
.thank-you-text {
  margin-left: 19px;
}
.thank-you-text p span img {
  width: 13px;
  height: 13px;
  object-fit: cover;
}
.just-now {
  color: #817c8c;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 11.6161px;
  line-height: 25px;
}
.set-design-wrapper {
  box-shadow: 0px 24.2003px 48.4005px rgba(0, 0, 0, 0.05);
  border-radius: 15.4882px;
  background: #ffffff;
  border: 0.193602px solid rgba(0, 0, 0, 0.1);
  padding: 11.6161px;
  max-width: 340px;
}
/* Banner css ends */

/* Trusted section starts */

.trusted-wrapper {
  background: #f4f6f6;
  padding: 50px 0px;
}
.brand-logo {
  margin: 0px 50px;
  width: 120px;
}
.brand-logo img {
  width: 100%;
  /* height: 24px; */
  object-fit: contain;
}
.trusted-text {
  color: #031745;
  text-align: center;
  letter-spacing: 0.14em;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
/* Trusted section ends */

/* Our services section starts */

.service-section .title-main-section {
  color: #ff7b29 !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 56px;
  text-align: center;
}

.service-section .title-main-section span {
  color: #2d3134;
}
.service-name {
  text-align: center;
  margin-top: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.5px;
  color: #2d3134;
}
.service-img {
  text-align: center;
}
.service-img img {
  width: 134px;
}
.service-text {
  color: #7d7987;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  text-align: justify;
  letter-spacing: 0.5px;
  margin-top: 20px;
}
.box-wrapper-service {
  box-shadow: 0px 23px 70px rgba(0, 0, 0, 0.06);
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 50px;
}
.read-more {
  margin-top: 20px;
  text-align: center;
}
.read-more span {
  color: #ff7b29;
  letter-spacing: 0.5px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  margin-right: 5px;
}
.read-more img {
  width: 24px;
}
.margin-top-30 {
  margin-top: 30px !important;
}
/* Our services section ends */

/* Why choose us section starts */

.why-img img {
  width: 100%;
  /* max-width: 541px; */
}
.why-choose-section {
  margin-top: 146px;
}
.why-choose-section .title-main-section {
  color: #ff7b29 !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 56px;
}
.why-choose-section .title-main-section span {
  color: #2d3134;
}
.why-text {
  color: #817c8c;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.01em;
  margin-top: 25px;
}
.box-text-wrapper {
  margin-left: 10px;
}
.why-icon img {
  width: 86px;
}
.why-wrapper {
  padding: 28px;
  border-radius: 10px;
  transition: 0.3s ease-in-out;
  margin-top: 60px;
}
.why-wrapper:hover {
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.06);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    #ffffff;
}
.box-text-wrapper .box-title {
  color: #272d4e;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 120%;
  letter-spacing: 0.5px;
}
.box-content {
  color: #7d7987;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.5px;
  margin-top: 8px;
}
/* Why choose  us section ends*/

/* Our product section starts */


.our-product-section .title-main-section {
  color: #ff7b29 !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 56px;
  text-align: center;
}
.our-product-section .title-main-section span {
  color: #2d3134;
}
.vikir-wrapper img {
  width: 100%;
  /* max-width: 403px;
  height: auto;
  object-fit: cover; */
}
.product-right-wrapper .pro-title {
  color: #2d3134;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
}
.margin-top-60 {
  margin-top: 60px !important;
}
.pro-text-small {
  margin-top: 30px;
  color: #676a6c;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 32px;
}
.pro-btn span img {
  width: 32px;
}
.pro-text-item {
  margin-left: 15px;
}
.pro-btn {
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  max-width: 330px;
  width: 100%;
  margin: 10px;
}
.pro-btn span {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16.778px;
  line-height: 22px;
}
.row-reverse .row {
  flex-direction: row-reverse;
}

/* Executive section starts */
.exe-detail .ex-title {
  color: #252f40;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 0px;
}
.position-ex {
  color: #3d51fb;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 14px;
}
.ex-detail-small {
  color: #67748e;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-top: 7px;
}
.exe-detail {
  margin-left: 24px;
}
.executive-img img {
  width: 188px;
  height: 188px;
  object-fit: cover;
  border-radius: 5px;
}
.executive-wrapper-main {
  background: #ffffff;
  box-shadow: 0px 20px 27px rgb(0 0 0 / 5%);
  border-radius: 16px;
  padding: 15px;
  max-width: 1500px;
  width: 50%;
  margin: 15px;
}
.executive-team-section {
  background-image: url(../src/assets/bg.png);
  background-size: 100% 250px;
  background-repeat: no-repeat;
  background-position: center;
}
/* Executive section ends */

/* Milestone section starts */
.milestone-section {
  margin-bottom: 142px;
}
.milestone-text {
  color: #7d7987;
  font-family: "Folito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.5px;
}
.blue-btn-milestone {
  background: #121d53;;
  box-shadow: 0px 25px 50px rgba(61, 81, 251, 0.15);
  border-radius: 8px;
  outline: none;
  border: none;
  color: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  padding: 13px 52px;
}
.video-btn {
  color: #3d51fb;
  border: 1px solid #3d51fb;
  outline: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  background-color: transparent;
}
.milestone-btn-wrapper {
  margin-top: 54px;
}
.video-btn {
  border-radius: 8px;
  padding: 13px 52px;
  margin-left: 50px;
}
.video-btn span {
  margin-left: 14px;
}
.box-card-milestone {
  background: #fafafa;
  border: 1px solid #e0e0e0;
  width: 192px;
  height: 161px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;
  margin: 0px 20px;
}
.title-mile {
  color: #313131;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
}
.pro-text-mile {
  color: #61646b;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  margin-top: 10px;
}
.box-card-milestone:hover {
  background: #ffffff;
  box-shadow: 0px 38.5185px 25.4815px rgba(56, 56, 56, 0.0607407),
    0px 20px 13px rgba(56, 56, 56, 0.05),
    0px 8.14815px 6.51852px rgba(56, 56, 56, 0.0392593),
    0px 1.85185px 3.14815px rgba(56, 56, 56, 0.0240741);
}
/* Milestone section ends */

/* Footer section starts */
.icon-footer img {
  width: 24px;
}
.footer-contact-text {
  color: #ffffff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-left: 15px;
}
.footer-section {
  background: #121d53;;
  padding: 100px 0px;
}
.pro-develop {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}
.pro-text-develop {
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  font-family: "Roboto";
  font-style: normal;
  margin-top: 30px;
}
.phone-span {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.middle-wrapper .middle-title {
  color: #ffffff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
}
.contact-detail-form {
  color: #ffffff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-right: 20px;
  white-space: nowrap;
}
.input-box-footer {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #ffffff;
  background-color: transparent;
  outline: none;
  color: #fff;
}
.input-box-footer::placeholder {
  color: #fff;
}
.send-msg button {
  color: #3d51fb;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background: #ffffff;
  border-radius: 10px;
  padding: 16px 26px;
  outline: none;
  border: none;
  margin-top: 30px;
  width: 100%;
}
.right-title {
  color: #ffffff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
}
.arrow-footer-link img {
  width: 7px;
}
.link-li a {
  color: #fff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
}
.link-li a:hover {
  color: #fff;
  text-decoration: none;
}
.margin-top-20 {
  margin-top: 20px;
}
.social-list ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.fb-width-icon img {
  width: 16px !important;
}
.social-list li {
  list-style: none;
}
.social-list li img {
  width: 28px;
}
.margin-top-42 {
  margin-top: 42px;
}
.margin-left-auto {
  margin-left: auto;
}
/* Footer section ends */

/* Progress section starts */
.progress-section {
  max-width: 80%;
  margin: 0 auto;
  margin-top: -180px;
  margin-bottom: 85px;
}
.progress-wrapper {
  background: #ff7b29;
  border-radius: 10px;
  padding: 48px 24px;
}
.progress-title {
  color: #ffffff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
}
.tech-wrapper .right-icon img {
  width: 12px;
}
.tech-text {
  color: #ffffff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-left: 10px;
}
.margin-top-18 {
  margin-top: 18px;
}
.margin-left-30 {
  margin-left: 30px;
}
.right-btn-wrapper button {
  outline: none;
  border: none;
}
.right-btn-wrapper .started {
  color: #3d51fb;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background: #fdfdfd;
  border-radius: 8px;
  padding: 11px 36px;
}
.right-btn-wrapper .contact-us {
  color: #ffffff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background: #121d53;;
  border-radius: 8px;
  padding: 11px 36px;
  margin-left: 24px;
}

.title-main-section {
  color: #ff7b29 !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 56px;
  text-align: center;
}
.title-main-section span {
  color: #2d3134;
}

.our-customer-body-section {
  background: #eceeff;
}
.our_customer_card {
  background: #ffffff;
  border-radius: 16px;
  height: 282px;
  padding: 40px 24px 26px 32px;
  width: 380px;
  margin-right:40px ;
}
.our_customer_card_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #425466;
  margin-top: 18px;
}
.our_customer_card_name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #313131;
}
.our_customer_body {
  margin-top: 81px;
  margin-bottom: 68px;
}
.our_customer_card_email {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 100%;
  /* identical to box height, or 10px */

  color: #425466;
}
.card_footer_section{
  margin-top: 24px;
}
.btn_bottom{
  background: #FFFFFF;
  width: 120px;
height: 66px;
padding: 8px;
margin: auto;
margin-bottom: 81px;
}

.right_btn{
width: 50px;
height: 50px;
outline: none;
border: none;
background: transparent;
}
.right_btn:hover{
  background: #FF7B29;
  box-shadow: 0px 8px 15px rgba(72, 72, 138, 0.08);
}

.icon{
  fill: #FFFFFF
}
.icon_rote{
  transform: rotate(
180deg);
}


/* mobile  */
.nav-ul-main-mobile{
  position: fixed;
    width: 100%;
    height: 100vh;
    background: #fff;
    top: 100px;
    text-align: center;
    transition: all 0.5s;
    padding: 0px;
}

/* Progress section ends */
@media only screen and (max-width: 1280px) {
  .progress-wrapper > div {
    flex-direction: column;
  }
  .right-progress {
    margin-top: 20px;
  }
  .progress-title {
    text-align: center;
  }
}

@media only screen and (max-width: 1079px) {
  .flex-trust {
    flex-direction: column;
  }
  .brand-logo {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 921px) {
  .executive-wrapper-main {
    flex-direction: column;
  }
  .exe-detail {
    margin-left: 0px;
    margin-top: 15px;
  }
  .executive-img {
    width: 100%;
  }
  .executive-img img {
    width: 100%;
    height: 100%;
  }
}
@media only screen and (max-width: 991px) {
  .margin-left-auto {
    margin-left: 0px !important;
    margin-top: 50px;
  }
  .right-box-section-milestone {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .res-margin-top-30 {
    margin-top: 50px;
  }
  .executive-wrapper-main {
    width: 100%;
  }
  .executive-wrapper > div {
    flex-direction: column;
  }
}
